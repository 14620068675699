import { io } from 'socket.io-client';

class SocketioService {
  socket;
  constructor() {}

  setupSocketConnection() {
    this.socket = io(process.env.VUE_APP_WSENDPOINT);
    this.socket.on('stat/' + 'tasmota_72A26F' + '/sensor', (data) => {
        console.log('Porcoddio', data);
    });
  }

  disconnect() {
      if (this.socket) {
          this.socket.disconnect();
      }
  }

}



export default new SocketioService();