<template>
    <h1 class="page-title">Routines</h1>
    <p-button class="m-2" icon="pi pi-file" label="Nuova" @click="this.$router.push({path: '/scheduler/routines/new'})" />
    <p-card class="m-2" v-for="job in jobs" :key="job.name">
        <template #content>
            <div class="flex flex-wrap justify-content-between">
                <h5>{{job.description}}</h5>
                <div>
                    <p-button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-primary" @click="editJob(job)"/>
                    <p-button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteJob(job)"/>
                </div>
            </div>
        </template>
    </p-card>            
</template>
<script>
export default {
    data() {
        return {
            jobs: [],
        }
    },
    created() {
        this.loadJobs();
    },
    methods: {
        
        loadJobs() {
            this.$root.loading = true;
            this.$apiClient.get('/scheduler/jobs/domotic-jobs')
                .then(({data: res}) => {
                    this.jobs = res;
                })
                .finally(() => {
                    this.$root.loading = false;
                })
        },
        editJob(job) {
            this.$router.push({name: 'edit_routine', params: {jobGroup: job.group, jobName: job.name}});
        },
        deleteJob(job) {
            this.$root.loading = true;
            this.$apiClient.delete('/scheduler/jobs/' + job.group + '/' + job.name)
                .then(() => {
                    this.loadJobs();
                })
        }
    }
}
</script>