<template>
    <div class="container">
        <p-breadcrumb :model="path">
            <template #item="{item}">
                <router-link :to="item.link" v-if="item.link != '#'">
                    {{item.name}}
                </router-link>
                <span v-else>{{item.name}}</span>
            </template>
        </p-breadcrumb> 
        <h1 class="page-title">{{jobDetail.description}}</h1>

        <div class="p-mt-3">
        
        <div class="p-fluid p-grid">
            <div class="p-field p-col-12 p-md-4">
                <span class="p-float-label">
                    <p-inputtext id="desc" type="text" v-model="jobDetail.description" />
                    <label for="desc">Descrizione della routine</label>
                </span>
            </div>
        </div>        
        

        <h5>Dispositivi</h5>   
        <div class="grid">
            <div v-for="device in payload" :key="device.deviceId" class="col-12 md:col-4 lg:col-3">
                <p-card>
                    <template #title>
                        {{device.name}}
                    </template>
                    <template #subtitle>
                        {{device.location.name}}
                    </template>
                    <template #content>

                        <div class="flex flex-wrap justify-content-center">
                            <div class="vld-parent" v-for="ss in device.switches" :key="ss.index">
                                <p-card class="m-1 device-switch">
                                    <template #content>
                                        <div class="text-centered">
                                            <p-tristatecheckbox v-model="ss.power" /><br/>
                                            {{ss.power != null ? (ss.power ? 'Acceso': 'Spento') : 'Invariato'}}
                                        </div>
                                    </template>
                                    <template #footer>
                                        <div class="text-centered">{{device.switches.length > 1 ? 'CH-' + (ss.index+1) : ''}}</div>
                                    </template>
                                </p-card>
                            </div>
                        </div>

                    </template>
                </p-card>
            </div>
        </div>

        
        
        </div>

        <p-datatable v-if="jobDetail.name" :value="triggers" responsiveLayout="scroll" :loading="triggerLoading">
            <template #header>
                 <div class="p-d-flex p-jc-between p-ai-center">
                    <h5 class="p-m-0">Triggers</h5>
                    <span class="p-input-icon-left">
                        <p-button class="btn-primary" @click="editTrigger({})">Nuovo</p-button>
                    </span>
                 </div>
            </template>

            <p-column field="description" header="Descrizione" />
            <p-column field="expressionSummary" header="Pianificazione" />
            <p-column field="nextFireTime" header="Prossima attivazione" />
            <p-column>
                <template #body="slot">
                    <div>
                        <p-button icon="pi pi-pencil" class="p-mr-2 p-button-rounded p-button-primary" @click="editTrigger(slot.data)"/>
                        <p-button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteTrigger(slot.data)"/>
                    </div>
                </template>
            </p-column>
        </p-datatable>

        <TriggerDialog
            id="dlg"
            :jobGroup="jobDetail.group"
            :jobName="jobDetail.name"
            :group="trigger.group" :name="trigger.name"
            :show="triggerDialog"
            :key="trigger.name"
            @close="triggerDialog = false"
            @save="onSaveTrigger"
            />

    </div>

     
        <div class="action-bar">
            <p-button icon="pi pi-arrow-left" label="Indietro" @click="this.$router.go(-1)" />
            <p-button icon="pi pi-save" label="Salva" @click="save" />
        </div>
    

</template>
<script>
import cronstrue from 'cronstrue/i18n';
import moment from 'moment';
import TriggerDialog from '@/components/TriggerDialog';

export default {
    components: {
        TriggerDialog
    },
    data() {
        return {
            devices: [],
            jobDetail: {group: 'domotic-jobs'},
            jobGroup: null,
            jobName: null,
            triggers: [],
            trigger: {},
            triggerLoading: false,
            triggerDialog: false,
            path: [{name: 'Routines', link: '/scheduler/routines'}],
            payload: []
        }
    },
    created() {
        this.jobGroup = this.$route.params.jobGroup;
        this.jobName = this.$route.params.jobName;
        if (this.jobGroup && this.jobName) {
            this.loadJobDetail();
        } else {
            this.path.push({name: 'Nuova routine', link: '#'});
            this.listDevices();
        }
    },
    methods: {
        loadJobDetail() {
            this.$root.loading = true;
            this.$apiClient.get('/scheduler/jobs/' + this.jobGroup + '/' + this.jobName)
                .then(({data: res}) => {
                    console.log(res);
                    this.jobDetail.group = res.group;
                    this.jobDetail.name = res.name;
                    this.jobDetail.description = res.description;
                    this.jobDetail.jobDataMap = res.jobDataMap;
                    this.jobDetail.jobDataMap.devices = JSON.parse(res.jobDataMap.devices);
                    this.path.push({name: this.jobDetail.description, link: '#'});
                    this.listDevices();
                    this.listTriggers();
                });
             
        },
        listTriggers() {
            this.triggerLoading = true;
            this.$apiClient.get('/scheduler/jobs/' + this.jobDetail.group + '/' + this.jobDetail.name + '/triggers')
                .then(({data: res}) => {
                    this.triggers = [];
                    for (var i = 0; i <res.length; i++) {
                        res[i].expressionSummary = cronstrue.toString(res[i].cronExpression, { locale: 'it' });
                        res[i].nextFireTime = moment(res[i].nextFireTime).locale('it').format('dddd D MMMM HH:mm');
                        this.triggers.push(res[i]);
                    }
                    
                })
                .finally(() => {
                    this.triggerLoading = false;
                })
        },
        editTrigger(t) {
            this.trigger = t;
            this.triggerDialog = true;
        },
        deleteTrigger(t) {
            this.$apiClient.delete('/scheduler/triggers/' + t.group + '/' + t.name)
            .then(({data: res}) => {
              console.log(res);
              if (res)
                this.listTriggers();
            });  
        },
        onSaveTrigger() {
            this.triggerDialog = false;
            this.listTriggers();
        },
        getSwitch(deviceId, index) {
            if (this.jobDetail.jobDataMap?.devices) {
                for (var i = 0; i < this.jobDetail.jobDataMap.devices.length; i++) {
                    var device = this.jobDetail.jobDataMap.devices[i];
                    if (device.deviceId == deviceId) {
                        if (device.switches) {
                            for (var j = 0; j < device.switches.length; j++) {
                                if (device.switches[j].index == index)
                                    return device.switches[j].power == 'on' ? true : false;
                            }
                        }
                        return null;                        
                    }
                }
            }
            return null;
        },
        listDevices() {
            this.$root.loading = true;
            this.device = [];
            this.$apiClient.get('/devices?active=true&features=power_switch&sortProperty=name&sortOrder=asc')
                .then(({data: res}) => {
                    console.log(res);
                    this.payload = [];
                    for (var i = 0; i < res.length; i++) {
                        var d = res[i];
                        
                        var elem = {deviceId: d.id, switches: [], name: d.name, location: d.location};
                        
                        for (var j = 0; j < d.numberOfSwitches; j++) {
                            var ss = {index: j, power: this.getSwitch(d.id, j)};
                            elem.switches.push(ss);
                        }
                        this.payload.push(elem);
                    }
                })
                .catch(error => {
                    this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 3000});              
                })
                .finally(() => {
                    this.$root.loading = false;
                })
        },
        listJobs() {
            this.$apiClient.get('/scheduler/jobs/domotic-jobs')
                .then(({data: res}) => {
                    console.log(res);
                    
                })
        },
        onTest(e) {
            console.log(e);
        },
        save() {
            this.jobDetail.jobDataMap = {};
            var dev = [];
            for (var i  = 0; i < this.payload.length; i++) {
                var switches = [];
                for (var j = 0; j < this.payload[i].switches.length; j++) {
                    var sw = this.payload[i].switches[j];
                    if (sw.power != null) {
                        switches.push({index: sw.index, power: sw.power ? 'on' : 'off'});
                    }
                }
                if (switches.length > 0) {
                    var elem = {deviceId: this.payload[i].deviceId, switches: switches};
                    dev.push(elem);
                }
            }

            this.jobDetail.jobDataMap.devices = dev;

            this.$apiClient.post('/scheduler/jobs', this.jobDetail)
                .then(({data: res}) => {
                    console.log(res);
                    this.$router.go(-1);
                })
        }
    }
}
</script>
<style scoped>
.state-control {
    text-align: center;
    width: 80px;
}

.state-control label {
    font-weight: bold;
    font-size: .8em;
}

.channel {
    border-top: solid 1px lightgray;
    padding: .5em 0 .5em 0;
}
</style>
<style scoped>
.container {
    padding-bottom: 80px;
}

.text-centered {
    text-align: center;
}

</style>