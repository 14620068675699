<template>
    <div class="grid">
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_all" name="mode" value="all" v-model="mode" />
                <label for="m_all">Ogni giorno</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_everydow" name="mode" value="everydow" v-model="mode" />
                <label for="m_everydow">Ogni <select v-model="every['dow']"><option v-for="(n, index) in daysOfWeek" :key="index" :value="index+1" :selected="index+1 == every['dow']">{{index+1}}</option></select> giorni a partire dal <select v-model="from['dow']"><option v-for="n in daysOfWeek" :key="n.stringValue" :value="n.stringValue" :selected="n.stringValue == from['dow']">{{n.name}}</option></select></label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_everydom" name="mode" value="everydom" v-model="mode" />
                <label for="m_everydom">Ogni <select v-model="every['dom']"><option v-for="n in valuesB1" :key="n" :value="n" :selected="n == every['dom']">{{n}}</option></select> giorni a partire dal <select v-model="from['dom']"><option v-for="n in valuesB1" :key="n" :value="n" :selected="n == from['dom']">{{n}}</option></select> del mese</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_specifieddow" name="mode" value="specifieddow" v-model="mode" />
                <label for="m_specifieddow">Nei giorni della settimana specificati</label>
            </div>
            <div class="d-flex flex-wrap p-ml-5 p-mr-5">
                <div v-for="n in daysOfWeek" :key="n" class="p-1">
                    <input type="checkbox" v-model="selected['dow']" :value="n.stringValue" :checked="selected['dow'].includes(n.stringValue)"/> {{n.name}}
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_specifieddom" name="mode" value="specifieddom" v-model="mode" />
                <label for="m_specifieddom">Nei giorni del mese specificati</label>
            </div>
            <div class="d-flex flex-wrap p-ml-5 p-mr-5">
                <div v-for="n in valuesB1" :key="n" class="p-1" style="min-width: 65px">
                    <input type="checkbox" v-model="selected['dom']" :value="n" :checked="selected['dom'].includes(n)"/> {{n}}
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_lastofmonth" name="mode" value="lastofmonth" v-model="mode" />
                <label for="m_lastofmonth">L'ultimo giorno del mese</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_lastweekday" name="mode" value="lastweekday" v-model="mode" />
                <label for="m_lastweekday">L'ultimo giorno della settimana del mese</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_lastweekdayofmonth" name="mode" value="lastweekdayofmonth" v-model="mode" />
                <label for="m_lastweekdayofmonth">Ultimo <select v-model="lastWeekDayOfMonth"><option v-for="n in daysOfWeek" :key="n.stringValue" :value="n.stringValue" :selected="n.stringValue == lastWeekDayOfMonth">{{n.name}}</option></select> del mese</label>
            </div>
        </div>        
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_daysbeforeendofmonth" name="mode" value="daysbeforeendofmonth" v-model="mode" />
                <label for="m_daysbeforeendofmonth"><select v-model="daysBeforeEndOfMonth"><option v-for="n in valuesB1" :key="n" :value="n" :selected="n == daysBeforeEndOfMonth">{{n}}</option></select> giorni prima della fine del mese</label>
            </div>
        </div>

        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_nearestweekdayto" name="mode" value="nearestweekdayto" v-model="mode" />
                <label for="m_nearestweekdayto">Giorno lavorativo più vicino al <select v-model="nearestWeekDayToDay"><option v-for="n in valuesB1" :key="n" :value="n" :selected="n == nearestWeekDayToDay">{{n}}</option></select> del mese</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_nthdayofweek" name="mode" value="nthdayofweek" v-model="mode" />
                <label for="m_nthdayofweek">
                    Il
                    <select v.model="nth">
                        <option :value="1" :selected="nth == 1">Primo</option>
                        <option :value="2" :selected="nth == 2">Secondo</option>
                        <option :value="3" :selected="nth == 3">Terzo</option>
                        <option :value="4" :selected="nth == 4">Quarto</option>
                        <option :value="5" :selected="nth == 5">Quinto</option>
                    </select>

                    <select v-model="nthDayOfWeek">
                        <option v-for="n in daysOfWeek" :key="n.stringValue" :value="n.stringValue" :selected="n.stringValue == nthDayOfWeek">{{n.name}}</option>
                    </select>
                    del mese
                </label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Days',
    props: {
        dayOfMonth: String,
        dayOfWeek: String,
    },
    emits: ['update:dayOfWeek', 'update:dayOfMonth', 'update:modelValue'],
    data() {
        return {
            mode: 'all',
            valuesB0: [],
            valuesB1: [],
            daysOfWeek: [
                {name: 'Lunedì', value: 2, stringValue: 'MON'}, 
                {name: 'Martedì', value: 3, stringValue: 'TUE'}, 
                {name: 'Mercoledì', value: 4, stringValue: 'WED'},
                {name: 'Giovedì', value: 5, stringValue: 'THU'},
                {name: 'Venerdì', value: 6, stringValue: 'FRI'},
                {name: 'Sabato', value: 7, stringValue: 'SAT'},
                {name: 'Domenica', value: 1, stringValue: 'SUN'}
            ],
            selected: {
                dom: [1],
                dow: ['MON'],
            },
            every: {
                dom: 1,
                dow: 1
            },
            from: {
                dom: 1,
                dow: 'MON'
            },
            lastWeekDayOfMonth: 'MON',
            daysBeforeEndOfMonth: 1,
            nearestWeekDayToDay: 1,
            nth: 1,
            nthDayOfWeek: 2,
        }
    },
    computed: {
        dom: {
            get() {
                return this.dayOfMonth;
            },
            set(value) {
                console.log('dayOfMonth', this.mode, '----------> ' + value);
                //this.$emit('update:modelValue', value);
                this.$emit('update:dayOfMonth', value);
            }
        },
        dow: {
            get() {
                return this.dayOfWeek;
            },
            set(value) {
                console.log('dayOfWeek', this.mode, '----------> ' + value);
                //this.$emit('update:modelValue', value);
                this.$emit('update:dayOfWeek', value)
            }
        },
    },
    watch: {
        mode: { 
            handler: function(newVal) {
                this.notifyChanges(newVal);
            }
        },
        selected: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
            deep: true
        },
        every: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
            deep: true
        },
        from: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
            deep: true
        },
        lastWeekDayOfMonth: {
            handler: function() {
                this.notifyChanges(this.mode);
            }
        },
        daysBeforeEndOfMonth: {
            handler: function() {
                this.notifyChanges(this.mode);
            }
        },
        nearestWeekDayToDay: {
            handler: function() {
                this.notifyChanges(this.mode);
            }
        },
        nth: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
        },
        nthDayOfWeek: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
        }
    },
    created() {
        for (var i = 0; i < 31; i++) {
            this.valuesB0[i] = i;
            this.valuesB1[i] = i+1;
        }
        console.log('created', this.dayOfMonth, this.dayOfWeek, this.dayOfMonth.match('^[0-9][0-9]?W'));

        var pos = 0;
        console.log('match?', this.dayOfWeek, this.dayOfWeek.match(/(MON|TUE|WED|THU|FRI|SAT|SUN)\/[0-9][0-9]?/gm) ? true : false);
        if (this.dayOfMonth == '?' && this.dayOfWeek == '*') {
            this.mode = 'all';
        } else if (this.dayOfMonth == '?' && this.dayOfWeek.match('('+this.daysOfWeek.map(o => {return o.stringValue}).join('|') + ')\\/[0-9]')) {
            this.mode = 'everydow';
            pos = this.dayOfWeek.indexOf('/');
            this.from['dow'] = this.dayOfWeek.substring(0, pos);
            this.every['dow'] = parseInt(this.dayOfWeek.substring(pos+1));
        } else if (this.dayOfMonth.match('[0-9][0-9]?\\/[0-9][0-9]?')  && this.dayOfWeek == '?') {
            this.mode = 'everydom';
            pos = this.dayOfMonth.indexOf('/');
            this.from['dom'] = parseInt(this.dayOfMonth.substring(0, pos));
            this.every['dom'] = parseInt(this.dayOfMonth.substring(pos+1));
        } else if (this.dayOfMonth == '?' && this.dayOfWeek.match('^((' +this.daysOfWeek.map(o => {return o.stringValue}).join('|') + ')(,)?)+$')) {
            this.mode = 'specifieddow';
            this.selected['dow'] = this.dayOfWeek.split(',');
        } else if (this.dayOfMonth.match('^([0-9][0-9]?(,)?)+$') && this.dayOfWeek == '?') {
            this.mode = 'specifieddom';
            this.selected['dom'] = this.dayOfMonth.split(',');
        } else if (this.dayOfMonth == 'L' && this.dayOfWeek == '?') {
            this.mode = 'lastofmonth'
        } else if (this.dayOfMonth == 'LW' && this.dayOfWeek == '?') {
            this.mode = 'lastweekday'
        } else if (this.dayOfMonth == '?' && this.dayOfWeek.match('^(' + this.daysOfWeek.map(o => {return o.stringValue}).join('|') + ')L')) {
            this.mode = 'lastweekdayofmonth';
            this.lastWeekDayOfMonth = this.dayOfWeek.substring(0, this.dayOfWeek.length-1);
            console.log('lastWeekDayOfMonth', this.lastWeekDayOfMonth);
        } else if (this.dayOfMonth.match('^L-([0-9][0-9]?)') && this.dayOfWeek == '?') {
            this.mode = 'daysbeforeendofmonth';
            this.daysBeforeEndOfMonth = parseInt(this.dayOfMonth.substring(2));
        } else if (this.dayOfMonth.match('^[0-9][0-9]?W') && this.dayOfWeek == '?') {
            this.mode = 'nearestweekdayto';
            this.nearestWeekDayToDay = parseInt(this.dayOfMonth.substring(0, this.dayOfMonth.length-1));
        } else if (this.dayOfMonth == '?' && this.dayOfWeek.match('^'+this.daysOfWeek.map(o => {return o.stringValue}).join('|') +'#[1-5]')) {
            this.mode = 'nthdayofweek';
            pos = this.dayOfWeek.indexOf('#');
            this.nthDayOfWeek = this.dayOfWeek.substring(0, pos);
            this.nth = parseInt(this.dayOfWeek.substring(pos+1));
        }

        console.log(this.mode);
    },
    methods: {
        notifyChanges(newVal) {
            switch(newVal) {
                case 'all':
                    this.dom = '?'
                    this.dow = '*';
                    break;
                case 'everydow':
                    this.dom = '?'
                    this.dow = this.from['dow'] + '/' + this.every['dow'] ;
                    break;
                case 'everydom':
                    this.dom = this.from['dom'] + '/' + this.every['dom'] ;
                    this.dow = '?'
                    break;
                case 'specifieddow':
                    this.dom = '?'
                    this.dow = this.selected['dow'].sort((a,b) => {return this.daysOfWeek.find((e) => {return e.stringValue == a}).value - this.daysOfWeek.find((e) => {return e.stringValue == b}).value}).join(',');
                    break;
                case 'specifieddom':
                    this.dom = this.selected['dom'].sort((a,b) => {return a-b}).join(',');
                    this.dow = '?';
                    break;
                case 'lastofmonth':
                    this.dom = 'L'
                    this.dow = '?';
                    break;
                case 'lastweekday':
                    this.dom = 'LW'
                    this.dow = '?';
                    break;
                case 'lastweekdayofmonth':  
                    this.dom = '?'
                    this.dow = this.lastWeekDayOfMonth+'L';
                    break;  
                case 'daysbeforeendofmonth':
                    this.dom = 'L-' + this.daysBeforeEndOfMonth;
                    this.dow = '?';
                    break;  
                case 'nearestweekdayto':
                    this.dom = this.nearestWeekDayToDay + 'W';
                    this.dow = '?';
                    break;  
                case 'nthdayofweek': {
                    this.dom = '?'
                    this.dow = this.nthDayOfWeek+'#'+this.nth;
                    break; 
                }
            }
        }
    }

}
</script>