<template>
    <form @keyup.enter="doSearch()">
        <div class="p-fluid grid justify-content-center mt-5">
            <div class="col-12 md:col-6 flex flex-wrap">
                <div v-for="device of devices" :key="device.id" class="field-checkbox" style="min-width: 170px">
                    <p-checkbox :id="device.id" name="deviceId" :value="device.id" v-model="deviceIds" />
                    <label :for="device.id">{{device.name}}</label>
                </div>
            </div>
            <div class="col-12 md:col-2">
                <span class="p-float-label">
                    <p-dropdown v-model="groupingType" id="groupingType" :options="groupingTypes" optionLabel="name" optionValue="id" />
                    <label for="groupingType">Intervallo</label>
                </span>
            </div>
            <div class="col-12 md:col-2">
                <span class="p-float-label">
                    <p-calendar v-model="startDate" id="startDate" :showTime="groupingType === 'hour'" :stepMinute="15" />
                    <label for="startDate">DAL</label>
                </span>
            </div>
            <div class="col-12 md:col-2">
                <span class="p-float-label">
                    <p-calendar v-model="endDate" id="endDate" :showTime="groupingType === 'hour'" :stepMinute="15"/>
                    <label for="endDate">AL</label>
                </span>
            </div>
            <p-button ref="filterButton" label="Carica dati" @click="doSearch()" class="p-button p-col-12" />
        </div>
        
    </form>

    
    <p-chart ref="chart" type="line" :data="chartData" :options="chartOptions" />
        
</template>
<script>
import {formatISODateTime, formatDisplayDateTime} from '../../utils/FormatUtils';
import moment from 'moment';

export default {
    data() {
        return {
            startDate: null,
            endDate: null,
            groupingType: null,
            starts: [],
            ends: [],
            params: new URLSearchParams(),
            dataLoading: false,
            devices: [],
            deviceIds: [],
            colors: ['blue', 'red', 'yellow', 'green', 'pink', 'grey', 'black'],
            chartOptions: {
            },
            chartData: {
                labels: [],
                datasets: []
            },
            chartKey: 0,
            groupingTypes: [
                {id: 'day', name: 'Giorni'},
                {id: 'hour', name: 'Ore'},
            ]
        }
    },
    created() {
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate()-1);
        this.endDate = new Date();
        this.groupingType = 'hour';


        this.$apiClient.get('/devices?active=true&features=energy_meter&sortProperty=name&sortOrder=asc')
            .then(res => {
                //console.log(res);
                this.devices = res.data;
            })
            .catch(error => {
                this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 3000});              
            })
            .finally(() => {
                this.$root.loading = false;
            })
        this.deviceIds = this.$route.query.deviceId;
        console.log(this.startDate, this.endDate);
    },
    mounted() {
        this.doSearch();
    },
    methods: {
        doSearch() {
            this.$root.loading = true;
            this.params.set('groupingType', this.groupingType);
            this.params.set('startDate', formatISODateTime(this.startDate));
            this.params.set('endDate', formatISODateTime(this.endDate));
            this.params.set('deviceId', this.deviceIds);
            console.log('Params', this.params.toString());
            this.$apiClient.get('/devices/consumption/details?' + this.params.toString())
                .then(({data: res}) => {
                    console.log(res);
                    this.chartData.datasets = [];
                    this.chartData.labels = [];
                    const startMoment = moment(this.startDate);
                    const endMoment = moment(this.endDate);
                    startMoment.set({hour: this.groupingType === 'day' ? 0 : startMoment.hour,minute:0,second:0,millisecond:0})
                    endMoment.set({hour: this.groupingType === 'day' ? 0 : endMoment.hour,minute:0,second:0,millisecond:0})
                    
                    this.chartData.labels.push(formatDisplayDateTime(startMoment.toDate()));
                    do {
                        startMoment.add(1, this.groupingType + 's');
                        this.chartData.labels.push(formatDisplayDateTime(startMoment.toDate()));
                    } while (startMoment.isBefore(endMoment, this.groupingType));

                    var dataSetIndex = -1;
                    var lastDeviceId = null;
                    for (var i = 0; i < res.length; i++) {
                        var e = res[i];
                        if (e.deviceId != lastDeviceId) {
                            lastDeviceId = e.deviceId;
                            dataSetIndex++;
                            this.chartData.datasets.push({
                                id: e.deviceId,
                                label: e.deviceName,
                                data: this.chartData.labels.map(elem => {return {x: elem, y: 0}}),
                                fill: false,
                                borderColor: this.colors[dataSetIndex],
                                tension: .4,
                            })
                        }
                        //if (dataSetIndex == 0)
                            //this.chartData.labels.push(formatDisplayDateTime(e.time));

                        const dataIndex = this.chartData.datasets[dataSetIndex].data.findIndex(elem => {return elem.x === formatDisplayDateTime(e.time)})
                        
                        if (dataIndex >= 0) {
                            this.chartData.datasets[dataSetIndex].data[dataIndex].y = e.consumption;
                        } else {
                            console.log('dataIndex', dataIndex, formatDisplayDateTime(e.time));
                        }
                    }

                    console.log('chartData', this.chartData.datasets[0].id);
                    this.$apiClient.get('/devices/consumption/totals?' + this.params.toString())
                        .then(({data: r}) => {
                            console.log('totals', r);
                            for (let i = 0; i < r.length; i++) {
                                const v = r[i];
                                const dataset = this.chartData.datasets.find(e => {return e.id === v.deviceId});
                                dataset.label = v.deviceName + ' - ' + v.consumption.toFixed(3) + 'kWh, ' + v.amount.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.$toast.add({severity:'error', summary: "Errore", detail: error.response?.data.message, life: 5000});
                        })

                })
                .catch(error => {
                    console.log(error);
                    this.$toast.add({severity:'error', summary: "Errore", detail: error.response?.data.message, life: 5000});
                })
                .finally(() => {
                    this.$root.loading = false;
                })
        }
    }
}
</script>