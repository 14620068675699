<template>
  <div>
  </div>
</template>

<script>
import SocketioService from '@/services/SocketioService.js';

export default {
    created() {
        SocketioService.setupSocketConnection();
    },
    beforeUnmount() {
        SocketioService.disconnect();
    },
}
</script>
