<template>
    <div class="grid">
        <div v-for="(device, i) in devices" :key="device.id" class="col-12 md:col-4 lg:col-3">
            <Device v-model="devices[i]" :refreshInterval="0"  />
        </div>
    </div>
    <p-button icon="pi pi-refresh" class="p-button-rounded p-button-primary" @click="reconnect()" />
</template>
<script>
import Device from '@/components/Device';
import mqtt from 'mqtt'

export default {
    name: 'Home',
    components: {Device},
    data() {
        return {
            devices: [],
            connection: {
                host: 'localhost',
                port: 9001,
                endpoint: '',
                clean: true, // Reserved session
                connectTimeout: 4000, // Time out
                reconnectPeriod: 4000, // Reconnection interval
                // Certification Information
                clientId: 'mqttjs_3be2c321',
                username: 'marco',
                password: 'MMa010977',
            },
            client: {
                connected: false,
            },
        }
    },
    created() {
        this.loadDeviceStatus();
    },
    methods: {
        loadDeviceStatus() {
            this.$root.loading = true;
            this.$apiClient.get('/devices?active=true&features=power_switch&sortProperty=name&sortOrder=asc')
                .then(res => {
                    console.log(res.data);
                    this.devices = res.data;
                    /*this.createConnection();
                    for (var i = 0; i < this.devices.length; i++) {
                        if (this.devices[i].type == 'tasmota') {
                            this.client.subscribe('stat/' + this.devices[i].id + '/POWER');
                        }
                    }
                    */
                })
                .catch(error => {
                    this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 3000});              
                })
                .finally(() => {
                    this.$root.loading = false;
                })
        },
        reconnect() {
            this.$root.loading = true;
            this.$apiClient.get('/ewelink/loginurl')
                .then((data) => {
                    console.log(data);
                    window.open(data, '_blank').focus();
                })
                .finally(() => {
                    this.$root.loading = false;
                })
        },
        createConnection() {
            // Connect string, and specify the connection method used through protocol
            // ws unencrypted WebSocket connection
            // wss encrypted WebSocket connection
            // mqtt unencrypted TCP connection
            // mqtts encrypted TCP connection
            // wxs WeChat mini app connection
            // alis Alipay mini app connection
            const { host, port, endpoint, ...options } = this.connection
            const connectUrl = `ws://${host}:${port}${endpoint}`
            try {
                this.client = mqtt.connect(connectUrl, options)
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
            this.client.on('connect', () => {
                console.log('Connection succeeded!')
            })
            this.client.on('error', error => {
                console.log('Connection failed', error);
            })
            this.client.on('message', (topic, message) => {
                //this.receiveNews = this.receiveNews.concat(message)
                console.log(`Received message ${message} from topic ${topic}`)
            })
        }
    }
}
</script>