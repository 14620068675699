<template>
    <p-menubar :model="menuItems"/>
    <div class="container">
        <router-view v-slot="{ Component }">
            <template v-if="$route.meta.keepAlive">
            <keep-alive >
                <component :is="Component" :key="$route.fullPath" />
            </keep-alive>
            </template>
            <template v-else>
            <component :is="Component" :key="$route.fullPath" />
            </template>
        </router-view>
    </div>
</template>
<script>
export default {
  name: 'Decorator',
  data() {
    return {
      loading: false,
      menuItems: [
        {
          label: 'Energy monitor',
          items: [
            {
              label: 'Realtime',
              to: '/energymonitor/realtime'
            },
            {
              label: 'Charts',
              to: '/energymonitor/consumption/chart'
            }
          ]
        },
        {
          label: 'TH monitor',
          items: [
            {
              label: 'Realtime',
              to: '/thmonitor/realtime'
            },
            {
              label: 'Charts',
              to: '/thmonitor/chart'
            }
          ]
        },
        {
          label: 'Interruttori',
          to: '/switches'
        },
        {
          label: 'Routines',
          to: '/scheduler/routines'
        }
      ]
    }
  },
  created() {
        if (this.$route.path == '/')
            this.$router.push({path: '/home'})        
    },
}
</script>
