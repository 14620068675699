<template>
    <div class="vld-parent">
        <Loading :active="loading" 
            :can-cancel="false" 
            background-color="#FFF"
            :opacity="0.4"
            :is-full-page="false"/>
        <p-blockui :blocked="!online">
            <p-card :class="(online ? 'online' : 'offline')">
                <template #title>
                    {{modelValue.name}} 
                    <!--
                    <p-badge value="P" :severity="powerTopic ? 'success': 'danger'" @click="stompConnect()"></p-badge>
                    <p-badge value="S" :severity="sensorTopic ? 'success': 'danger'" @click="stompConnect()"></p-badge>
                    <br/>
                    {{modelValue.id}} 
                    -->
                </template>
                <template #subtitle>
                    {{modelValue.location.name}}
                </template>
                <template #content>
                    <div v-if="modelValue.features.includes('energy_meter') && showSensorData">

                        <ejs-circulargauge ref="gauge" :margin='margin'  height='300' >
                            <e-axes>
                                <e-axis minimum="0" :maximum="modelValue.maxPower">
                                    <e-pointers>
                                        <e-pointer ref="pointer" :value='0' :lineStyle='lineStyle'></e-pointer>
                                    </e-pointers>
                                    <e-ranges>
                                        <e-range :start="0" :end="modelValue.maxPower/3" startWidth="15" endWidth="15"></e-range>
                                        <e-range :start="modelValue.maxPower/3" :end="(modelValue.maxPower/3)*2" startWidth="15" endWidth="15"></e-range>
                                        <e-range :start="(modelValue.maxPower/3)*2" :end="modelValue.maxPower" startWidth="15" endWidth="15"></e-range>
                                    </e-ranges>
                                </e-axis>
                            </e-axes>
                        </ejs-circulargauge>
                        
                        <div class="grid">
                            <div class="col-12">
                                <p-button style="width: 100%" icon="pi pi-chart-line" label="Grafico consumi" @click="$router.push({name: 'device_consumption_chart', query: {deviceId: [modelValue.id]}})" />
                            </div>
                            
                            <div class="col-6">Voltage</div>
                            <div class="col-6">{{sensorStatus.energy?.voltage}}</div>

                            <div class="col-6">Current</div>
                            <div class="col-6">{{sensorStatus.energy?.current}}</div>

                            <div class="col-6">Power</div>
                            <div class="col-6">{{sensorStatus.energy?.power}}</div>
                        </div>
                    </div>
                    
                    <div v-if="modelValue.features.includes('th_sensor') && showSensorData">
                        <div class="grid">
                            <div class="col-12">
                                <p-button style="width: 100%" icon="pi pi-chart-line" label="Grafico" @click="$router.push({name: 'device_th_chart', query: {deviceId: [modelValue.id]}})" />
                            </div>
                            
                            <div class="col-6">Temperatura</div>
                            <div class="col-6">
                                <span class="text-2xl font-bold" v-show="sensorStatus.th?.temperature">{{sensorStatus.th?.temperature}}°</span>
                            </div>

                            <div class="col-6">Umidità</div>
                            <div class="col-6">
                                <span class="text-2xl font-bold" v-show="sensorStatus.th?.humidity">{{sensorStatus.th?.humidity}}%</span>
                            </div>

                            <div class="col-6">Punto di rugiada</div>
                            <div class="col-6">
                                <span class="text-2xl font-bold" v-show="sensorStatus.th?.dewPoint">{{sensorStatus.th?.dewPoint}}°</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-content-center">
                        <div class="vld-parent" v-for="(ss, i) in deviceStatus.switches" :key="ss.index">
                            <Loading :active="toggling[i]" 
                                :can-cancel="false" 
                                background-color="#000"
                                :opacity="0.4"
                                :is-full-page="false"/>
                            <p-card class="m-1 device-switch" @click="toggleDeviceState(ss.index, ss.power)">
                                <template #content>
                                    <div class="text-centered">
                                        <p-badge size="xlarge" :class="'pi pi-power-off powered-' + (ss.power ? ss.power : 'offline')"  />
                                    </div>
                                </template>
                                <template #footer>
                                    <div class="text-centered">{{deviceStatus.switches.length > 1 ? ss.name + ' ' : ''}}</div>
                                </template>
                            </p-card>
                        </div>
                    </div>
                </template>

            </p-card>        
        </p-blockui>
    </div>
</template>
<script>
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import { CircularGaugeComponent, AxesDirective, AxisDirective, PointersDirective, PointerDirective, RangesDirective, RangeDirective } from '@syncfusion/ej2-vue-circulargauge'
import mqtt from 'mqtt'

export default {
    name: 'Device',
    components: {
        Loading,
        'ejs-circulargauge' : CircularGaugeComponent,
        'e-axes' : AxesDirective,
        'e-axis' : AxisDirective,
        'e-pointers': PointersDirective,
        'e-pointer' : PointerDirective,
        'e-ranges' : RangesDirective,
        'e-range' : RangeDirective
    },
    props: {
        modelValue: {
            type: Object
        },
        showSensorData: {
            type: Boolean,
            default: false
        },
        refreshInterval: {
            type: Number,
            default: 3000
        }
    },
    data() {
        return {
            loading: false,
            toggling: [false, false, false, false],
            margin: { left: 0, right: 0, top: 0, bottom: 0 },
            lineStyle: { width: 0 },
            online: false,
            sensorStatus: {},
            deviceStatus: {switches: []},
            unmounted: false,
            powerTopic: null,
            sensorTopic: null,
            powerConnected: false,
            sensorConnected: false,
            client: null
        }
    },
    created() {
    },
    beforeUnmount() {
    },
    async mounted() {
        console.log('mounted', this.modelValue.name);
        this.loading = true;

        await this.loadDeviceStatus();
        //const socket = new SockJS(process.env.VUE_APP_WSENDPOINT);
        //this.stompClient = Stomp.over(socket);

        const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)

        const options = {
            keepalive: 60,
            clientId: clientId,
            protocolId: 'MQTT',
            protocolVersion: 4,
            clean: true,
            reconnectPeriod: 1000,
            connectTimeout: 30 * 1000    
        }

        console.log('Connecting mqtt client')
        this.client = mqtt.connect(process.env.VUE_APP_WSENDPOINT, options)

        this.client.on('error', (err) => {
            console.log('Connection error: ', err)
            this.client.end()
        })

        this.client.on('disconnect', () => {
            console.log('WebSocket client disconnected!')
        })

        this.client.on('close', () => {
            console.log('WebSocket client closed!')
        })

        this.client.on('offline', () => {
            console.log('WebSocket client offline!')
        })

        this.client.on('reconnect', () => {
            console.log('Reconnecting...')
        })

        this.client.on('connect', () => {
            console.log('Client connected:' + clientId)
            this.client.subscribe('stats/' + this.modelValue.id + '/power')
            if (this.modelValue.hasSensors && this.showSensorData) {
                console.log(this.modelValue.name, 'Subscribing to sensor topic')
                this.client.subscribe('stats/' + this.modelValue.id + '/sensor');
            }
        })

        this.client.on('message', (topic, message) => {
            console.log('Received Message: ' + message.toString() + '\nOn topic: ' + topic)
            const sw = JSON.parse(message.toString());
            if (topic === 'stats/' + this.modelValue.id + '/power') {
                this.powerConnected = true;
                console.log('Eccolo!', sw);
                const toggleIndex = sw.index == 0 ? 0 : sw.index-1;
                if (this.toggling[toggleIndex] && this.modelValue.hasSensors && this.showSensorData)
                    this.loadSensorData();
                this.toggling[toggleIndex] = false;
                if (this.deviceStatus.switches)
                    this.deviceStatus.switches[toggleIndex].power = sw.power;
            } else if (topic === 'stats/' + this.modelValue.id + '/sensor') {
                this.sensorConnected = true;
                console.log('Eccolo!',this.modelValue.name, sw);
                this.sensorStatus = sw;
                if (this.$refs.gauge)
                    this.$refs.gauge.setPointerValue(0, 0, this.sensorStatus.energy?.power);
            }
            
        })    
    },
    unmounted() {
        console.log('unmounted', this.modelValue.name);
        this.unmounted = true;
        this.client.unsubscribe('stats/' + this.modelValue.id + '/power')
        this.client.unsubscribe('stats/' + this.modelValue.id + '/sensor')
        this.client.end();
        //this.powerTopic?.unsubscribe();
        //this.sensorTopic?.unsubscribe();

    },
    methods: {
        /*
        stompConnect() {
            console.log(this.modelValue.name, 'Connessione a ws...')
            
                    
                    
                                        
              
        },
        */
        async loadDeviceStatus() {
            console.log('*****************************', this.modelValue.name)
            try {
                const res = await this.$apiClient.get('/devices/' + this.modelValue.id + '/status' )
            
                console.log(this.modelValue.name, res, this.modelValue.features);
                this.deviceStatus = res.data;
                this.online = this.deviceStatus.online;
                
                //this.stompConnect();

                /*if (interval > 0 && !this.unmounted)
                    setTimeout(() => {this.loadDeviceStatus(interval)}, interval);*/
             } catch(error) {
                this.online = false;
                if (!this.unmounted)
                    this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 5000});              
             } finally {
                this.loading = false;
             }
        },
        loadSensorData() {
            console.log('---------------------------> loadSensorData')
            this.$apiClient.get('/devices/' + this.modelValue.id + '/sensor/status' )
            .then(res => {
                console.log('Sensor data', res.data);
                this.sensorStatus = res.data;
                if (this.$refs.gauge)
                    this.$refs.gauge.setPointerValue(0, 0, this.sensorStatus.energy?.power);
            })
            .catch(error => {
                if (!this.unmounted)
                    this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 3000});              
            })
            .finally(() => {
                this.loading = false;
            })
        },
        toggleDeviceState(index, state) {
            this.toggling[index] = true;
            this.$apiClient.post('/devices/' + this.modelValue.id + '/' +index + '/power', {power: state == 'on' ? 'off' : 'on'})
                .then(() => {
                })
                .catch(error => {
                    this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 3000});              
                })
                .finally(() => {
                    //this.toggling[index] = false;
                })

        }
    }
}
</script>
<style>
.text-centered {
    text-align: center;
}

.p-card .p-card-content {
    padding: 0!important;
}

.device-switch {
    min-width: 120px;
    min-height: 135px;
    cursor: pointer;
}

.powered-on {
    background-color: green;
    color: white;
}

.powered-off {
    background-color: grey;
    color: black;
}

.powered-offline {
    background-color: grey;
    color: black;
}
</style>