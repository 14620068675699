<template>
    <p-dialog header="Trigger" :modal="true" :closable="false" :visible="show" :style="{width: '850px'}">
      <div class="p-fluid grid mt-1">
        <div class="field col-12">
            <span class="p-float-label">
                <p-inputtext id="desc" v-model="trigger.description" />
                <label for="desc">Descrizione</label>
            </span>
        </div>
        
        <div class="field col-12">
            <span class="p-float-label">
                <p-calendar id="startTime" v-model="trigger.startTime" :showTime="true" />
                <label for="startTime">Inizio</label>
            </span>
        </div>
        
        <!--div class="field col-12">
            <span class="p-float-label">
                <p-inputtext id="cron" v-model="trigger.cronExpression" />
                <label for="cron">Espressione CRON</label>
            </span>
        </div-->
        <div class="field col-12">
          <CronEditor v-model="trigger.cronExpression" :showDescription="false" :showExpression="false" :key="trigger.name" />
        </div>
      </div>
      
      <template #footer>
        <p-button label="Annulla" icon="pi pi-times" class="p-button-text" @click="closeModal()"/>
        <p-button label="Salva" icon="pi pi-disk" autofocus @click="saveChanges" />
      </template>
    </p-dialog>

</template>

<script>

import CronEditor from './croneditor/CronEditor';

export default {
    name: 'TriggerDialog',
    components: {CronEditor},
    props: {
        jobGroup: String,
        jobName: String,
        group: String,
        name: String,
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
        trigger: {},
        key: new Date().getTime()
      }
    },
    mounted() {
        console.log('Dialog mounted');
        if (this.group && this.name) {
          this.$apiClient.get('/scheduler/triggers/' + this.group + '/' + this.name)
            .then(({data: res}) => {
              console.log(res);
              this.trigger = res;
            });   
        } else {
          this.trigger = {group: 'domotic-triggers'}
        }
    },
    activated() {
        console.log('Dialog activated')
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      saveChanges() {
        this.$apiClient.post('/scheduler/jobs/' + this.jobGroup + '/' + this.jobName + '/triggers', this.trigger)
          .then(({data: res}) => {
            console.log(res);
            this.$emit('save', res);
          })
        
      }
    }
}
</script>
<style scoped>
</style>