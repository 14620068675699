<template>
    <div class="grid">
        <div v-for="(device, i) in devices" :key="device.id" class="col-12 md:col-4 lg:col-3">
            <Device v-model="devices[i]" :showSensorData="true"  />
        </div>
    </div>
</template>
<script>
import Device from '@/components/Device';

export default {
    name: 'Home',
    components: {Device},
    data() {
        return {
            devices: [],
        }
    },
    created() {
        console.log('app', this.$apiClient, this.$auth, this.$stocazzo)
        this.$root.loading = true;
        this.$apiClient.get('/devices?active=true&features=th_sensor&sortProperty=name&sortOrder=asc')
            .then(res => {
                console.log(res.data);
                this.devices = res.data;
            })
            .catch(error => {
                this.$toast.add({severity:'error', summary: 'Errore', detail: error.response?.data.message, life: 3000});              
            })
            .finally(() => {
                this.$root.loading = false;
            })
    }
}
</script>