<template>
    <div v-if="showExpression">{{modelValue}}</div>
    <div v-if="showDescription">{{describe()}}</div>
    <p-tabview>
        <p-tabpanel header="Secondi">
            <Seconds v-model="value[0]" />
        </p-tabpanel>
        <p-tabpanel header="Minuti">
            <Minutes v-model="value[1]" />
        </p-tabpanel>
        <p-tabpanel header="Ore">
            <Hours v-model="value[2]" />
        </p-tabpanel>
        <p-tabpanel header="Giorni">
            <Days v-model:dayOfMonth="value[3]" v-model:dayOfWeek="value[5]" />
        </p-tabpanel>
        <p-tabpanel header="Mesi">
            <Months v-model="value[4]" />
        </p-tabpanel>
        <p-tabpanel header="Anni">
            <Years v-model="value[6]" />
        </p-tabpanel>
    </p-tabview>
</template>
<script>
import Seconds from './Seconds';
import Minutes from './Minutes';
import Hours from './Hours';
import Days from './Days';
import Months from './Months';
import Years from './Years';
import cronstrue from 'cronstrue/i18n';

export default {
    name: 'CronEditor',
    components: {Seconds, Minutes, Hours, Days, Months, Years},
    props: {
        modelValue: {
            type: String,
            default: '* * * * * ? *'
        },
        showExpression: {
            type: Boolean,
            defaut: true
        },
        showDescription: {
            type: Boolean,
            defaut: false
        }

    },
    emits: ['update:modelValue'],
    data() {
        return {
            value: []
        }
    },
    watch: { 
        modelValue: function(newVal) {
            this.value = newVal.split(' ');
        }, 
        value: {
            handler(newVal) {
                console.log('modelValue', newVal);
                this.$emit('update:modelValue', newVal.join(' '));
            },
            deep: true,
        },       
        
    },
    created() {
        this.value = this.modelValue.split(' ');
        if (this.value.length == 0) {
            this.value = '* * * * * ? *'.split(' ');
        }
    },
    methods: {
        describe() {
            return cronstrue.toString(this.modelValue, { locale: 'it' })
        }
    }
}
</script>