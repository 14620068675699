import moment from 'moment';

export function formatDisplayDate(value) {
    if (value) {
        var res = moment(value).locale('it').format('DD/MM/YYYY');
        return res;
    }
}

export function formatDisplayDateLong(value) {
    if (value) {
        var res = moment(value).locale('it').format('dddd D MMMM YYYY');
        return res;
    }
}

export function formatDisplayDateTime(value) {
    if (value) {
        var res = moment(value).locale('it').format('DD/MM/YYYY HH:mm');
        return res;
    }
}

export function formatDisplayDateTimeLong(value) {
    if (value) {
        var res = moment(value).locale('it').format('dddd D MMMM YYYY [ore] HH:mm');
        return res;
    }
}

export function formatISODate(value) {
    if (value) {
        var res = moment(value).locale('it').format('YYYY-MM-DD');
        return res;
    }
}

export function formatISODateTime(value) {
    if (value) {
        var res = moment(value).locale('it').format('YYYY-MM-DDTHH:mm:ss');
        return res;
    }
}

export function formatAmount(value) {
    if (value != null) {
        return (value).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
        });
    }
}

export function formatPercentage(value) {
    if (value != null) {
        return (value/100).toLocaleString('it-IT', {
            style: 'percent'
        });
    }
}