<template>
    <div class="grid">
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_all" name="mode" value="all" v-model="mode" />
                <label for="m_all">Ogni minuto</label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_every" name="mode" value="every" v-model="mode" />
                <label for="m_every">Ogni <select v-model="every"><option v-for="n in valuesB1" :key="n" :value="n" :selected="n == every">{{n}}</option></select> minuti a partire dal minuto <select v-model="from"><option v-for="n in valuesB0" :key="n" :value="n" :selected="n == from">{{n}}</option></select></label>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_specified" name="mode" value="specified" v-model="mode" />
                <label for="m_specified">Ai minuti specificati</label>
            </div>
            <div class="d-flex flex-wrap p-ml-5 p-mr-5">
                <div v-for="n in valuesB0" :key="n" class="p-1" style="min-width: 65px">
                    <input type="checkbox" v-model="selected" :value="n" :checked="selected.includes(n)"/> {{n}}
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="field-radiobutton">
                <p-radiobutton id="m_between" name="mode" value="between" v-model="mode" />
                <label for="m_between">Ogni minuto tra il minuto <select v-model="start"><option v-for="n in valuesB0" :key="n" :value="n" :selected="n == start">{{n}}</option></select> e il minuto <select v-model="end"><option v-for="n in valuesB0" :key="n" :value="n" :selected="n == end">{{n}}</option></select></label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Minutes',
    props: {
        modelValue: String
    },
    emits: ['update:modelValue'],
    data() {
        return {
            mode: 'all',
            valuesB0: [],
            valuesB1: [],
            selected: [0],
            every: 1,
            from: 0,
            start: 0,
            end: 0,
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                console.log(this.mode, '----------> ' + value);
                this.$emit('update:modelValue', value)
            }
        },
    },
    watch: {
        mode: { 
            handler: function(newVal) {
                this.notifyChanges(newVal);
            }
        },
        selected: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
            deep: true
        },
        every: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
        },
        from: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
        },
        start: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
        },
        end: { 
            handler: function() {
                this.notifyChanges(this.mode);
            },
        }
    },
    created() {
        for (var i = 0; i < 60; i++) {
            this.valuesB0[i] = i;
            this.valuesB1[i] = i+1;
        }
        var pos = 0;
        if (this.modelValue == '*') {
                this.mode = 'all'
        } else if (this.modelValue.match('[0-9]\\/[0-9]')) {
                this.mode = 'every';
                pos = this.modelValue.indexOf('/');
                this.from = parseInt(this.modelValue.substring(0, pos));
                this.every = parseInt(this.modelValue.substring(pos+1));
        } else if (this.modelValue.match('[0-9]-[0-9]')) {
                this.mode = 'between';
                pos = this.modelValue.indexOf('-');
                this.start = parseInt(this.modelValue.substring(0, pos));
                this.end = parseInt(this.modelValue.substring(pos+1));
        } else {
            this.mode = 'specified';
            this.selected = this.modelValue.split(',');
        }
    },
    methods: {
        notifyChanges(newVal) {
            switch(newVal) {
                case 'all':
                    this.value = '*';
                    break;
                case 'every':
                    this.value = this.from + '/' + this.every;
                    break;
                case 'specified':
                    this.value = this.selected.sort((a,b) => {return a-b}).join(',');
                    break;
                case 'between':
                    this.value = this.start + '-' + this.end;
                    break;
            }
        }
    }

}
</script>